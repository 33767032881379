import Vue from 'vue'
import Vuex from 'vuex'
// import { address } from './address'
// import { cart } from './cart'
// import { menu } from './menu'
// import { order } from './order'
// import { language } from './language'
// import { user } from './user'

Vue.use(Vuex)

export const store = new Vuex.Store({
    modules: {
        // address,
        // cart,
        // menu,
        // order,
        // user,
        // language
    }
})
