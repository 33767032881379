<template>
  <div id="app" class="__main_container">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  mounted() {
    document.title = 'Нөмөр кредит ББСБ';
  },
  name: "App",
  components: {
  },
};
</script>

<style>
</style>
